// TODO qac: once export works without webpack enable this approach
// import LuxonUtils from "@material-ui/pickers/adapter/luxon";
import LuxonUtils from "@date-io/luxon"
import { LocalizationProvider } from "@material-ui/pickers"
import React from "react"
import Paperbase, { IIProps } from "./paperbase/Paperbase"

class Layout extends React.Component<IIProps> {
  public render() {
    const { children, title } = this.props

    return (
      <LocalizationProvider dateAdapter={LuxonUtils}>
        <Paperbase title={title}>{children}</Paperbase>
      </LocalizationProvider>
    )
  }
}

export default Layout
