import CssBaseline from "@material-ui/core/CssBaseline"
import Hidden from "@material-ui/core/Hidden"
import { withStyles } from "@material-ui/core/styles"
import { ThemeProvider } from "@material-ui/styles"
import React from "react"
import theme from "../../admin-theme"
import Header, { IProps } from "./Header"
import Navigator from "./Navigator"

export interface IIProps extends IProps {
  classes?: any
  children: any
  title?: string
}

const drawerWidth = 256

const styles = {
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column" as const,
    overflowX: "hidden" as const,
  },
  mainContent: {
    flex: 1,
    padding: "48px 36px 0",
    background: "#eaeff1",
  },
}

class Paperbase extends React.Component<IIProps, { mobileOpen: boolean }> {
  public state = {
    mobileOpen: false,
  }

  public handleDrawerToggle = () => {
    this.setState((state) => ({ mobileOpen: !state.mobileOpen }))
  }

  public componentDidMount() {
    const jssStyles = document.querySelector("#jss-server-side")
    if (jssStyles) {
      jssStyles.parentNode!.removeChild(jssStyles)
    }
  }

  public render() {
    const { classes, children, title } = this.props
    return (
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          <nav className={classes.drawer}>
            <Hidden smUp={true} implementation="js">
              <Navigator
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
              />
            </Hidden>
            <Hidden xsDown={true} implementation="css">
              <Navigator PaperProps={{ style: { width: drawerWidth } }} />
            </Hidden>
          </nav>
          <div className={classes.appContent}>
            <Header onDrawerToggle={this.handleDrawerToggle} title={title} />
            <main className={classes.mainContent}>{children}</main>
          </div>
        </div>
      </ThemeProvider>
    )
  }
}

export default withStyles(styles)(Paperbase)
