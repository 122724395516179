import { useQuery } from "@apollo/client"
import Divider from "@material-ui/core/Divider"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import { withStyles } from "@material-ui/core/styles"
import AccessTime from "@material-ui/icons/AccessTime"
import DeleteForever from "@material-ui/icons/DeleteForever"
import Lock from "@material-ui/icons/Lock"
import SwapHoriz from "@material-ui/icons/SwapHoriz"
import DnsRoundedIcon from "@material-ui/icons/DnsRounded"
import CachedIcon from "@material-ui/icons/Cached"
import EmailIcon from "@material-ui/icons/Email"
import GetAppIcon from "@material-ui/icons/GetApp"
import HistoryIcon from "@material-ui/icons/History"
import SportsFootballIcon from "@material-ui/icons/SportsFootball"
import HomeIcon from "@material-ui/icons/Home"
import SettingsIcon from "@material-ui/icons/Settings"
import ListIcon from "@material-ui/icons/List"
import SettingsEthernetIcon from "@material-ui/icons/SettingsEthernet"
import SubjectIcon from "@material-ui/icons/Subject"
import PollIcon from "@material-ui/icons/Poll"
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel"
import clsx from "clsx"
import React from "react"
import { Link, RouteComponentProps, withRouter } from "react-router-dom"
import { AdminSQLTablesQuery } from "../../../../__generated__/AdminSQLTablesQuery"
import { emptyArray } from "../../../../common/misc-utils"
import { ADMIN_TABLES_QUERY } from "../../queries"
import BracketLineSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Bracket"
import PersonAddDisabled from "@material-ui/icons/PersonAddDisabled"
import Sports from "@material-ui/icons/Sports"
import School from "@material-ui/icons/School"
// import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';

const categories = [
  {
    id: "Games",
    children: [
      // { id: 'Authentication', icon: <PeopleIcon />, active: true },
      { id: "Game Instances", icon: <DnsRoundedIcon />, to: "/admin/game-instances", label: "Game Instances" },
      // { id: 'Storage', icon: <PermMediaOutlinedIcon /> },
      // { id: 'Hosting', icon: <PublicIcon /> },
      // { id: 'Functions', icon: <SettingsEthernetIcon /> },
      // { id: 'ML Kit', icon: <SettingsInputComponentIcon /> },
    ],
  },
  {
    id: "Marketing",
    children: [
      // { id: "Authentication", icon: <PeopleIcon /> },
      { id: "Segments", icon: <GetAppIcon />, to: "/admin/marketing/segment", label: "Segments" },
      { id: "EmailTemplates", icon: <EmailIcon />, to: "/admin/marketing/email-templates", label: "Email Templates" },
      { id: "CustomCopy", icon: <SubjectIcon />, to: "/admin/marketing/custom-copy", label: "Custom Copy" },
      { id: "WhenToWatchManager", icon: <SubjectIcon />, to: "/admin/marketing/when-to-watch-manager", label: "When to Watch" },
      { id: "NewsletterManager", icon: <SubjectIcon />, to: "/admin/marketing/newsletter-manager", label: "Newsletter" },
      // { id: 'Performance', icon: <TimerIcon /> },
      // { id: 'Test Lab', icon: <PhonelinkSetupIcon /> },
    ],
  },
  {
    id: "Scoring",
    children: [
      { id: "OPC", icon: <SportsFootballIcon />, to: "/admin/scoring/opc", label: "OPC" },
      {
        id: "FFBOPC",
        icon: (
          <>
            <SportsFootballIcon />
            <School />
          </>
        ),
        to: "/admin/scoring/ffbopc",
        label: "FFBOPC",
      },
    ],
  },
  {
    id: "Ops",
    children: [{ id: "Ops Service Scaling", icon: <SettingsEthernetIcon />, to: "/admin/ops/service-scaling", label: "Service Scaling" }],
  },
  {
    id: "User",
    children: [
      { id: "Delete User", icon: <PersonAddDisabled />, to: "/admin/user/delete-user", label: "Delete User" },
      { id: "Pick Log", icon: <HistoryIcon />, to: "/admin/user/pick-log", label: "Pick Log" },
    ],
  },
  {
    id: "Sim",
    children: [
      { id: "Date Bump", icon: <AccessTime />, to: "/admin/sim/date-bump", label: "Date Bump" },
      { id: "Bracket States", icon: <BracketLineSvg />, to: "/admin/sim/bracket-states", label: "Bracket States" },
      { id: "Game Simulation", icon: <Sports />, to: "/admin/sim/game-simulation", label: "Game Simulation" },
    ],
  },
  {
    id: "Event Helpers",
    children: [
      {
        id: "Remove Event From Slate",
        icon: <DeleteForever />,
        to: "/admin/event-helpers/remove-event-from-slate",
        label: "Remove Event From Slate",
      },
      { id: "Lock Event Down", icon: <Lock />, to: "/admin/event-helpers/lock-event-down", label: "Lock Event Down" },
      { id: "Swap Events With Bad Ids", icon: <SwapHoriz />, to: "/admin/event-helpers/swap-event-ids", label: "Swap Events With Bad Ids" },
    ],
  },
  {
    id: "Cache",
    children: [{ id: "Cache Manager", icon: <CachedIcon />, to: "/admin/cache", label: "Cache Manager" }],
  },
  {
    id: "Analytics",
    children: [{ id: "Pool Data", icon: <PollIcon />, to: "/admin/analytics", label: "Statistics By Game" }],
  },
  {
    id: "Database",
    children: [
      // { id: "Authentication", icon: <PeopleIcon /> },
      { id: "Tables", icon: <SettingsIcon />, to: "/admin/database", label: "All Tables" },
      // { id: 'Performance', icon: <TimerIcon /> },
      // { id: 'Test Lab', icon: <PhonelinkSetupIcon /> },
    ],
  },
]

// const categories = [
//   {
//     id: 'Develop',
//     children: [
//       { id: 'Authentication', icon: <PeopleIcon />, active: true },
//       { id: 'Database', icon: <DnsRoundedIcon /> },
//       { id: 'Storage', icon: <PermMediaOutlinedIcon /> },
//       { id: 'Hosting', icon: <PublicIcon /> },
//       { id: 'Functions', icon: <SettingsEthernetIcon /> },
//       { id: 'ML Kit', icon: <SettingsInputComponentIcon /> },
//     ],
//   },
//   {
//     id: 'Quality',
//     children: [
//       { id: 'Analytics', icon: <SettingsIcon /> },
//       { id: 'Performance', icon: <TimerIcon /> },
//       { id: 'Test Lab', icon: <PhonelinkSetupIcon /> },
//     ],
//   },
// ];

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 4,
    paddingBottom: 4,
    color: "rgba(255, 255, 255, 0.7)",
  },
  itemCategory: {
    backgroundColor: "#232f3e",
    boxShadow: "0 -1px 0 #404854 inset",
    paddingTop: 16,
    paddingBottom: 16,
  },
  firebase: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white,
  },
  itemActionable: {
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.08)",
    },
  },
  itemActiveItem: {
    color: "#4fc3f7",
  },
  itemPrimary: {
    color: "inherit",
    fontSize: theme.typography.fontSize,
  },
  divider: {
    marginTop: theme.spacing(2),
  },
})
interface INavigatorProps extends RouteComponentProps<any> {
  classes?: any
  PaperProps: any
  variant?: "permanent" | "persistent" | "temporary"
  open?: boolean
  onClose?: () => void
}

const Navigator: React.FC<INavigatorProps> = (props) => {
  const { classes, location, history, match, staticContext, ...other } = props
  const listTablesQuery = useQuery<AdminSQLTablesQuery>(ADMIN_TABLES_QUERY)
  const tableNames = listTablesQuery.data?.listTables || emptyArray

  const itemPrimary: string = classes.itemPrimary
  const itemTextDense: string = classes.textDense

  const isInPoolArea = /\/admin\/maint\/pool\//.test(props.location.pathname)

  if (isInPoolArea) {
    const poolId = props.match.params.poolId
    const baseUrl = `/admin/maint/pool/${poolId}`

    const categoriesPoolArea = [
      {
        id: "Pool Maintainance",
        children: [
          { id: "Overview", icon: <ViewCarouselIcon />, to: `${baseUrl}`, label: "Pool Overview" },
          { id: "Activities", icon: <ListIcon />, to: `${baseUrl}/activities`, label: "Pool Activities" },
        ],
      },
    ]

    return (
      <Drawer variant="permanent" {...other}>
        <List disablePadding={true}>
          <ListItem
            button={true}
            dense={true}
            className={clsx(classes.item, classes.itemActionable, classes.itemCategory)}
            component={Link}
            to={`/admin`}
          >
            Picks Admin
          </ListItem>
          <ListItem className={clsx(classes.item, classes.itemCategory)}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
              }}
            >
              Project Overview
            </ListItemText>
          </ListItem>

          {categoriesPoolArea.map(({ id, children }) => {
            const allChildren = children.concat([])

            return (
              <React.Fragment key={id}>
                <ListItem className={classes.categoryHeader}>
                  <ListItemText
                    classes={{
                      primary: classes.categoryHeaderPrimary,
                    }}
                  >
                    {id}
                  </ListItemText>
                </ListItem>
                {allChildren.map(({ id: childId, icon, to, label }) => (
                  <ListItem
                    button={true}
                    dense={true}
                    key={childId}
                    className={clsx(classes.item, classes.itemActionable, location.pathname.includes(to || "nope") && classes.itemActiveItem)}
                    component={to ? Link : "div"}
                    to={to}
                  >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText
                      classes={{
                        primary: itemPrimary,
                        dense: itemTextDense,
                      }}
                    >
                      {label || childId}
                    </ListItemText>
                  </ListItem>
                ))}
                <Divider className={classes.divider} />
              </React.Fragment>
            )
          })}
        </List>
      </Drawer>
    )
  }

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding={true}>
        <ListItem
          className={clsx(classes.firebase, classes.item, classes.itemCategory, classes.itemActionable)}
          button={true}
          component={Link}
          to="/admin"
        >
          Picks Admin
        </ListItem>
        {categories.map(({ id, children }) => {
          const additionalChildren =
            id === "Database"
              ? tableNames.map((table) => ({
                  id: `t-${table}`,
                  icon: <DnsRoundedIcon />,
                  to: `/admin/database?table=${table}`,
                  label: table || "(none)",
                }))
              : emptyArray
          const allChildren = children.concat(additionalChildren)
          return (
            <React.Fragment key={id}>
              <ListItem className={classes.categoryHeader}>
                <ListItemText
                  classes={{
                    primary: classes.categoryHeaderPrimary,
                  }}
                >
                  {id}
                </ListItemText>
              </ListItem>
              {allChildren.map(({ id: childId, icon, to, label }) => (
                <ListItem
                  button={true}
                  dense={true}
                  key={childId}
                  className={clsx(classes.item, classes.itemActionable, location.pathname.includes(to || "nope") && classes.itemActiveItem)}
                  component={to ? Link : "div"}
                  to={to}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: itemPrimary,
                      dense: itemTextDense,
                    }}
                  >
                    {label || childId}
                  </ListItemText>
                </ListItem>
              ))}
              <Divider className={classes.divider} />
            </React.Fragment>
          )
        })}
      </List>
    </Drawer>
  )
}

export default withRouter(withStyles(styles)(Navigator))
