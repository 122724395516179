import AppBar from "@material-ui/core/AppBar"
import Avatar from "@material-ui/core/Avatar"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import IconButton from "@material-ui/core/IconButton"
import { withStyles } from "@material-ui/core/styles"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import Toolbar from "@material-ui/core/Toolbar"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import HelpIcon from "@material-ui/icons/Help"
import MenuIcon from "@material-ui/icons/Menu"
import React, { useContext } from "react"
import { Link } from "react-router-dom"
import PoolDataContext, { PoolDataContextType } from "../../../Contexts/PoolDataContext"

interface ISubnavLink {
  label: string
  href: string
}
export type ISubnavLinks = ISubnavLink[]
export interface IProps {
  subnavLinks?: ISubnavLink
  title?: string
}

const lightColor = "rgba(255, 255, 255, 0.7)"

const styles = (theme) => ({
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: "none",
    color: lightColor,
    "&:hover": {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
})

function Header(props) {
  const { classes, onDrawerToggle } = props
  const title = props.title || "Admin Page"
  const subnavLinks = props.subnavLinks || []
  const poolDataContext: PoolDataContextType = useContext(PoolDataContext)

  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container={true} spacing={1} alignItems="center">
            <Hidden smUp={true}>
              <Grid item={true}>
                <IconButton aria-label="Open drawer" onClick={onDrawerToggle} className={classes.menuButton}>
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item={true} xs={true} />
            {/*<Grid item={true}>
              <Typography className={classes.link} component="a" href="#">
                Go to docs
              </Typography>
            </Grid>
            <Grid item={true}>
              <Tooltip title="Alerts • No alters">
                <IconButton>
                  <NotificationsIcon />
                </IconButton>
              </Tooltip>
            </Grid>*/}
            <Grid item={true}>
              <IconButton color="secondary" className={classes.iconButtonAvatar}>
                <Avatar
                  className={classes.avatar}
                  src={poolDataContext?.centralCurrentUsersEntriesQuery?.data?.currentUser.avatarUrl || "//placehold.it/150x150"}
                />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar component="div" className={classes.secondaryBar} color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container={true} alignItems="center" spacing={1}>
            <Grid item={true} xs={true}>
              <Typography variant="h5">{title}</Typography>
            </Grid>
            {/*<Grid item={true}>
              <Button className={classes.button} variant="outlined" color="secondary" size="small">
                Web setup
              </Button>
            </Grid>*/}
            <Grid item={true}>
              <Tooltip title="Help">
                <IconButton>
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {(subnavLinks.length && (
        <AppBar component="div" className={classes.secondaryBar} color="primary" position="static" elevation={0}>
          <Tabs value={0} textColor="inherit">
            {subnavLinks.map(({ label, href }, i) => (
              <Tab key={i} textColor="inherit" label={label} to={href} component={Link} />
            ))}
          </Tabs>
        </AppBar>
      )) ||
        null}
    </React.Fragment>
  )
}

export default withStyles(styles)(Header)
